<template>
  <v-container fluid class="down-top-padding">
    <v-card class="white pa-3">
      <h1 class="text-center subtitle-4 black--text">ايرادات اخرى</h1>
      <h5 class="text-center subtitle-4 black--text mt-1">
        العدد الكلي {{ table.totalData }}
      </h5>
      <h5 class="text-center subtitle-4 black--text mt-1">
        اجمالي المدفوعات :
        {{ numberWithComma(table.amount) }}
      </h5>
      <v-row class="mt-5">
        <v-col md="4" cols="12">
          <div class="d-md-flex">
            <v-btn
              tile
              color="info"
              class="ml-2 mb-4 mb-md-0 mb-sm-0"
              @click="dialogAdd.open = true"
            >
              اضافة <v-icon right> fa-plus </v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="2" cols="12">
          <v-menu
            v-model="menuStartDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="tableModifier.start_date"
                dense
                label="من"
                outlined
                clearable
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="tableModifier.start_date"
              @input="menuStartDate = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col md="2" cols="12">
          <v-menu
            v-model="menuEndDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="tableModifier.end_date"
                dense
                label="الى"
                outlined
                clearable
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="tableModifier.end_date"
              @input="menuEndDate = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-spacer></v-spacer>
        <v-col md="4" cols="12">
          <div class="d-flex flex-row">
            <v-text-field
              v-model="searching"
              label="البحث"
              outlined
              dense
              @keyup.enter="search()"
            ></v-text-field>
            <button class="search-btn" @click="search()">
              <v-icon style="font-size: 18px; color: white"> fa-search </v-icon>
            </button>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="table.headers"
            loading-text="جاري التحميل ... الرجاء الانتظار"
            :items="table.data"
            :options.sync="tableOptions"
            :server-items-length="table.totalData"
            :loading="table.loading"
            class="elevation-1"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-plus',
              nextIcon: 'mdi-minus',
              itemsPerPageOptions: [10, 50, 100],
            }"
          >
            <template slot="item._id" slot-scope="props">
              {{
                (tableOptions.page - 1) * tableOptions.itemsPerPage +
                props.index +
                1
              }}
            </template>
            <template v-slot:item.date="{ item }">
              {{ item.date.split("T")[0] }}
            </template>
            <template v-slot:item.money="{ item }">
              {{ numberWithComma(item.money) }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="info"
                    class="mx-2"
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="Edit(item)"
                  >
                    fa-edit
                  </v-icon>
                </template>
                <span>تعديل</span>
              </v-tooltip>
              <v-tooltip bottom v-if="$store.state.results.type === 'admin'">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="deleteItem(item)"
                  >
                    fa-trash
                  </v-icon>
                </template>
                <span>حذف</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>

    <!-- add dialog -->
    <v-dialog v-model="dialogAdd.open" max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="headline">اضافة</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="dialogAdd.isFormValid">
              <v-row dense>
                <!-- name -->
                <v-col cols="12" md="4">
                  <v-text-field
                    class="font-weight-black"
                    v-model="addData.name"
                    :rules="Rules.required"
                    dense
                    label="اسم الوارد"
                    outlined
                  />
                </v-col>
                <!-- money -->
                <v-col cols="12" md="4">
                  <v-text-field
                    class="font-weight-black"
                    v-model="addData.money"
                    :rules="Rules.required"
                    dense
                    label="المبلغ"
                    outlined
                    type="number"
                  />
                </v-col>
                <!-- payment.date -->
                <v-col cols="12" md="4">
                  <v-menu
                    v-model="start_date"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="addData.date"
                        label="تاريخ الاضافة"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                        outlined
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="addData.date"
                      @input="start_date = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <!-- courses_note -->
                <v-col cols="12">
                  <v-textarea
                    v-model="addData.note"
                    rows="4"
                    dense
                    label="الملاحظة"
                    outlined
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-6">
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="clearAddData">
            الغاء
          </v-btn>
          <v-btn
            color="secondary white--text"
            :loading="dialogAdd.loading"
            :disabled="!dialogAdd.isFormValid"
            @click="submitAdd"
          >
            اضافة
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- add discount dialog -->
    <!-- edit dialog -->
    <v-dialog v-model="dialogEdit.open" max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="headline">تعديل</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="dialogEdit.isFormValid">
              <v-row dense>
                <!-- name -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="editedItem.name"
                    :rules="Rules.required"
                    dense
                    label="اسم الوارد"
                    outlined
                  />
                </v-col>
                <!-- money -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="editedItem.money"
                    rows="2"
                    dense
                    label="المبلغ"
                    outlined
                    type="number"
                    @keypress="isNumberWithComma"
                  />
                </v-col>

                <!-- date -->
                <v-col cols="12" md="4">
                  <v-menu
                    v-model="editDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editedItem.date"
                        label="تاريخ التعديل"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                        outlined
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="editedItem.date"
                      @input="editDate = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <!-- note -->
                <v-col cols="12">
                  <v-textarea
                    v-model="editedItem.note"
                    rows="4"
                    dense
                    label="الملاحظة"
                    outlined
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-6">
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogEdit.open = false">
            الغاء
          </v-btn>
          <v-btn
            color="secondary white--text"
            :loading="dialogEdit.loading"
            :disabled="!dialogEdit.isFormValid"
            @click="submitEdit"
          >
            تعديل
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- edit discount dialog -->
    <!-- delete dialog -->
    <v-dialog v-model="dialogDelete.open" max-width="500px">
      <v-card style="border-radius: 0">
        <v-card-title class="headline justify-center">
          هل انت متأكد من حذف هذا الحساب ؟
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete.open = false">
            الغاء
          </v-btn>
          <v-btn
            color="primary white--text"
            :loading="dialogDelete.loading"
            @click="deleteItemConfirm"
          >
            حذف
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- edit table dialog -->
    <!--- respondMessageVue -->
    <respondMessageVue :dialogData="dialogData"></respondMessageVue>
  </v-container>
</template>

<script>
// ES modules
import otherPaymentApi from "@/api/otherPyments";
import respondMessageVue from "@/components/respondMessage.vue";
import numberWithComma from "@/constants/number";
import { encrypt, decrypt } from "@/constants/cryptUrl";
import { changeUtcDateToString } from "@/constants/date";
export default {
  name: "subscriptions",

  components: {
    respondMessageVue,
  },

  data: () => ({
    content_url: null,

    menuEndDate: false,

    menuStartDate: false,

    start_date: false,

    editDate: false,

    tableModifier: {
      start_date: null,
      end_date: null,
    },

    xlsxData: {
      list: null,
      listLoading: true,
      downloadLoading: false,
      filename: "الاشتراكات",
      autoWidth: true,
      bookType: "xlsx",
    },

    xlsxDataPayment: {
      list: null,
      listLoading: true,
      downloadLoading: false,
      filename: "المدفوعات بتاريخ معين",
      autoWidth: true,
      bookType: "xlsx",
    },

    allData: [],

    allDataPayment: [],

    expanded: [],

    time_picker: false,

    start_date_registration: false,

    end_date_registration: false,

    rate: 0,

    courses_selected: {},

    week_days: [
      "السبت",
      "الاحد",
      "الاثنين",
      "الثلاثاء",
      "الاربعاء",
      "الخميس",
      "الجمعة",
    ],

    errors: {},

    is_active_select_items: [
      { text: "مفعل", value: true },
      { text: "غير مفعل", value: false },
    ],

    addData: {
      name: null,
      money: null,
      date: null,
      note: null,
    },

    Rules: {
      required: [(value) => !!value || "الحقل مطلوب"],
    },

    searching: null,

    tableOptions: {
      page: 1,
      itemsPerPage: 10,
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
      sortBy: [],
      sortDesc: [],
      search: null,
      firstTime: true,
    },

    table: {
      search: null,
      totalData: 0,
      amount: 0,
      data: [],
      loading: true,

      headers: [
        {
          text: "#",
          align: "start",
          sortable: false,
          value: "_id",
        },
        {
          text: "اسم الوارد",
          sortable: false,
          value: "name",
        },
        {
          text: "الملاحظة",
          sortable: false,
          value: "note",
        },
        {
          text: "المبلغ",
          sortable: false,
          value: "money",
        },
        {
          text: "تأريخ الاضافة",
          sortable: false,
          value: "date",
        },
        {
          text: "اضيف بواسطة",
          sortable: false,
          value: "added_by",
        },
        { text: "العمليات", value: "actions", sortable: false },
      ],
    },

    dialogData: {
      open: false,
      color: "info",
      bodyText: "test",
    },

    all_courses: {
      data: [],
      loading: false,
    },

    all_customers: {
      data: [],
      loading: false,
    },

    editedItem: {
      _id: null,
      courses_created_id: null,
      customer_id: null,
      payment: {
        money: null,
        date: null,
      },
      courses_lecturer_price: null,
      courses_lecturer_price_note: null,
      courses_agency_price: null,
      courses_agency_price_note: null,
      courses_lecturer: null,
      discount: null,
      discount_note: null,
      note: null,
    },

    dialogDelete: {
      open: false,
      loading: false,
    },

    dialogEdit: {
      open: false,
      loading: false,
      isFormValid: false,
    },

    dialogInfo: {
      open: false,
      data: {},
    },

    dialogAdd: {
      open: false,
      loading: false,
      isFormValid: false,
    },
  }),

  watch: {
    tableOptions: {
      // immediate: false,

      handler() {
        if (!this.tableOptions.firstTime) {
          this.$router.push(
            {
              query: {
                filter: encrypt({
                  page: this.tableOptions.page,
                  limit: this.tableOptions.itemsPerPage,
                  search: this.table.search,
                }),
              },
            },
            () => {}
          );
        }

        this.tableOptions.firstTime = false;

        // this.getDataAxios();
      },
      // deep: true,
    },

    "$route.query.filter": {
      handler() {
        if (!this.$route.query.filter) {
          this.tableOptions.page = 1;
          this.tableOptions.itemsPerPage = 10;
          this.table.search = null;
          this.searching = null;
          return;
        }
        const filterData = decrypt(this.$route.query.filter);

        let page = filterData.page;
        let limit = filterData.limit;
        let search = filterData.search;

        this.tableOptions.page = +page;
        this.tableOptions.itemsPerPage = +limit;
        this.table.search = search;
        this.searching = search;

        this.getDataAxios();
      },
    },

    "table.search": {
      handler() {
        this.tableOptions.page = 1;
        this.tableOptions.itemsPerPage = 10;
        this.$router.push(
          {
            query: {
              filter: encrypt({
                page: this.tableOptions.page,
                limit: this.tableOptions.itemsPerPage,
                search: this.table.search,
              }),
            },
          },
          () => {}
        );
      },
    },

    tableModifier: {
      handler() {
        this.getDataAxios();
      },
      deep: true,
    },
  },

  created() {
    this.getDataAxios();
  },
  methods: {
    clearAddData() {
      this.dialogAdd.open = false;
      this.addData = {
        name: null,
        money: null,
        date: null,
        note: null,
      };
    },
    async getDataAxios() {
      this.table.loading = true;
      let search = this.table.search;
      let page = this.tableOptions.page;
      let limit = this.tableOptions.itemsPerPage;

      if (!search) {
        search = "";
      }

      const response = await otherPaymentApi.get({
        page,
        limit,
        search,
        start_date: this.tableModifier.start_date,
        end_date: this.tableModifier.end_date,
      });

      if (response.status === 401) {
        this.$store.dispatch("submitLogout");
      } else if (response.status === 500) {
        this.table.loading = false;
        this.showDialogFunction(response.data.results, "#FF5252");
      } else {
        this.table.loading = false;
        this.table.data = response.data.results.data;
        this.table.totalData = response.data.results.count;
        this.table.amount = response.data.results.amount;
      }
    },

    Edit(item) {
      this.editedItem = { ...item };
      this.editedItem.date = this.changeUtcDateToString(item.date);
      this.dialogEdit.open = true;
    },

    async submitEdit() {
      this.dialogEdit.loading = true;

      const response = await otherPaymentApi.edit({
        id: this.editedItem._id,
        name: this.editedItem.name,
        money: this.editedItem.money,
        date: this.editedItem.date,
        note: this.editedItem.note,
      });

      if (response.status === 401) {
        this.$store.dispatch("submitLogout");
      } else if (response.status === 500) {
        this.dialogEdit.open = false;
        this.dialogEdit.loading = false;
        this.showDialogFunction(response.data.message, "#FF5252");
      } else {
        this.dialogEdit.loading = false;
        this.dialogEdit.open = false;
        this.getDataAxios();
        this.showDialogFunction(response.data.message, "info");
      }
    },

    deleteItem(item) {
      this.deletedItem = { ...item };
      this.dialogDelete.open = true;
    },

    async deleteItemConfirm() {
      this.dialogDelete.loading = true;

      const response = await otherPaymentApi.remove(this.deletedItem._id);

      if (response.status === 401) {
        this.$store.dispatch("submitLogout");
      } else if (response.status === 500) {
        this.dialogDelete.loading = false;
        this.dialogDelete.open = false;
        this.showDialogFunction(response.data.message, "#FF5252");
      } else {
        this.dialogDelete.loading = false;
        this.dialogDelete.open = false;
        this.getDataAxios();
        this.showDialogFunction(response.data.message, "info");
      }
    },

    async submitAdd() {
      this.dialogAdd.loading = true;

      const response = await otherPaymentApi.add({
        name: this.addData.name,
        money: this.addData.money,
        date: this.addData.date,
        note: this.addData.note,
      });

      if (response.status === 401) {
        this.$store.dispatch("submitLogout");
      } else if (response.status === 500) {
        this.dialogAdd.open = false;
        this.dialogAdd.loading = false;
        this.showDialogFunction(response.data.message, "#FF5252");
      } else {
        this.dialogAdd.loading = false;
        this.dialogAdd.open = false;

        this.addData = {
          name: null,
          money: null,
          date: null,
          note: null,
        };

        this.showDialogFunction(response.data.message, "info");
        this.getDataAxios();
      }
    },

    search() {
      this.table.search = this.searching;
    },

    showDialogFunction(bodyText, color) {
      this.dialogData.open = true;
      this.dialogData.bodyText = bodyText;
      this.dialogData.color = color;
    },

    isNumber(evt) {
      const keysAllowed = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
      const keyPressed = evt.key;
      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault();
      }
    },

    removeItemInTableHeader(item) {
      for (const iterator of this.table.headers) {
        iterator.value = item;
      }
    },

    isNumberWithComma(evt) {
      const keysAllowed = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        ".",
      ];
      const keyPressed = evt.key;
      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault();
      }
    },

    numberWithComma,

    showInfo(item) {
      this.dialogInfo.data = { ...item };
      this.dialogInfo.open = true;
    },

    on_courses_selected() {
      if (this.courses_selected) {
        this.addData.courses_created_id = this.courses_selected._id;
        this.addData.courses_lecturer_price =
          this.courses_selected.courses_lecturer_price;
        this.addData.courses_agency_price =
          this.courses_selected.courses_agency_price;
        this.addData.courses_price = this.courses_selected.courses_price;
        this.addData.courses_start_date = this.changeUtcDateToString(
          this.courses_selected.courses_start_date
        );
      } else {
        this.addData.courses_created_id = null;
        this.addData.courses_lecturer_price = null;
        this.addData.courses_agency_price = null;
        this.addData.courses_price = null;
        this.addData.courses_start_date = null;
      }
    },

    showPaymentPage(item) {
      this.$router.push({
        name: "payment",
        query: {
          id: item._id,
          customer_name: item.name,
        },
      });
    },

    goToPrintPage(item) {
      let routeData = this.$router.resolve({
        name: `print`,
      });
      localStorage.setItem("print", JSON.stringify(item));
      window.open(routeData.href, "_blank");
    },

    handleDownload() {
      import("@/vendor/Export2Excel").then((excel) => {
        const tHeader = [
          "اسم الزبون",
          "اسم الدورة",
          "سعر الدورة",
          "المدفوع",
          "الخصم",
          "المتبقي",
        ];
        const filterVal = [
          "customer_name",
          "courses_name",
          "courses_price",
          "total_payment",
          "discount",
          "remaining_money",
        ];

        // const { list } = this
        const data = this.formatJson(filterVal, this.allData);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: this.xlsxData.filename,
          autoWidth: this.xlsxData.autoWidth,
          bookType: this.xlsxData.bookType,
        });
        this.xlsxData.downloadLoading = false;
      });
    },

    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },

    handleDownloadPayment() {
      import("@/vendor/Export2Excel").then((excel) => {
        const tHeader = ["اسم الزبون", "المبلغ", "التاريخ", "اضيف بواسطة"];
        const filterVal = ["customer_name", "money", "date", "added_by"];

        // const { list } = this
        const data = this.formatJson(filterVal, this.allDataPayment);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: this.xlsxData.filename,
          autoWidth: this.xlsxData.autoWidth,
          bookType: this.xlsxData.bookType,
        });
        this.xlsxData.downloadLoading = false;
      });
    },

    changeUtcDateToString,
  },
};
</script>

<style scoped>
.info_list li {
  padding: 10px 0;
}

.info_list li div {
  display: flex;
  gap: 7px;
  align-items: center;
}

.info_list li div h4 {
  background-color: #662e91;
  color: white;
  padding: 5px 15px;
  border-radius: 15px;
}

.info_list li span {
  font-weight: bold;
}
</style>
